<!-- 病房智能交互系统 -->
<template>
  <div class="wrapper pageBox product-wrapper">
    <Banner title="专病（慢病）健康管理系统" banner="https://static.zhongjiadata.com/images/20250302/43117eeb909e40f29b006ad802947674.png" describe="区域慢病管理平台依据慢病管理规范和诊疗路径设计，应用大数据、物联网及人工智能技术，实现对慢病预防、诊断、治疗、康复各环节的全过程健康管理，集体征监测、健康评估、 病程管理、生活方式干预为一体，为区域慢病患者实现健康档案动态化 、 健康检测常态化 、 慢病管理数字化、健康教育普及化以及数据分析智能化。" />
    <div class="product-content">
      <div class="product-name">产品亮点</div>
      <div class="pro-content">
        <div v-for="(item, index) in dataList" :key="index" class="content-item">
          <div class="item-title">
            <div class="title-bg">
              <img :src="item.src" alt="">
            </div>
            <div class="title-text">{{ item.title }}</div>
          </div>
          <div class="item-des">
            {{ item.des }}
          </div>
        </div>
      </div>
      <div class="evaluate-content">
        <div class="evaluate-warp">
          <div class="evaluate-title">评估管理</div>
          <div class="img-list">
            <img src="@/assets/product/evaluate1.png" class="img" alt="">
            <img src="@/assets/product/evaluate2.png" class="img" alt="">
            <img src="@/assets/product/evaluate3.png" class="img" alt="">
          </div>
          <div class="title-list">
            <div class="text">疾病高危筛查</div>
            <div class="line" />
            <div class="text">生活方式评估</div>
            <div class="line" />
            <div class="text">中医体质辨识</div>
          </div>
        </div>
      </div>
      <div class="product-name">档案管理</div>
      <div class="archival-content">
        <div class="content-item first">
          档案管理
        </div>
        <div v-for="(item, index) in dataList2" :key="index" class="content-item" :class="[`bg-${index + 1}`]">
          <div class="item-i">{{ `0${index + 1}` }}</div>
          <div class="item-title">
            {{ item.title }}
          </div>
          <div class="item-des">
            {{ item.des }}
          </div>
        </div>
      </div>
      <div class="introduction-content">
        <img src="@/assets/product/healthManage2.png" class="img2" alt="">
      </div>
    </div>
  </div>
</template>

<script>

import Banner from '@/components/Banner';
export default {
  name: '',
  components: {
    Banner
  },
  data() {
    return {
      dataList: [
        {
          src: require('@/assets/product/healthManage-1.png'),
          title: '居民列表',
          des: '汇总管理所有已采集的居民信息，并支持根据多种条件灵活控制当前操作人可见的居民数据范围。系统支持通过专用接口自动采集或手动录入居民数据，并为每一位居民自动建立个人电子健康档案。'
        },
        {
          src: require('@/assets/product/healthManage-1.png'),
          title: '家庭管理',
          des: '系统支持维护和管理居民家庭信息，可设置居民的家庭成员，将同属于一个家庭的居民信息进行关联，以便开展以家庭为单位的健康管理服务。'
        },
        {
          src: require('@/assets/product/healthManage-1.png'),
          title: '患者反馈',
          des: '系统支持针对患者反馈的表扬、建议、批评等信息进行集中管理。支持针对患者的反馈进行实时处理，并通过患者端即时提醒患者查看回复内容。'
        },
        {
          src: require('@/assets/product/healthManage-1.png'),
          title: '迁档管理',
          des: '支持对系统内管理的居民进行档案的迁移管理，同时变更居民所属的管理机构。包括迁出申请以及迁入审核等操作均可以形成记录。'
        }
      ],
      dataList2: [
        {
          title: '患者专栏档案',
          des: '汇总管理所有已采集的居民信息，并支持根据多种条件灵活控制当前操作人可见的居民数据范围。系统支持通过专用接口自动采集或手动录入居民数据，并为每一位居民自动建立个人电子健康档案。'
        },
        {
          title: '电子病历',
          des: '系统支持对接HIS系统，获取患者的院内诊疗数据，能够以时间轴的形式完整呈现该患者的电子病历资料，包括住院信息、出院小结、门诊信息、检查、检验、手术记录、用药记录等。'
        },
        {
          title: '就医记录',
          des: '系统支持通过手动录入的形式，维护患者的院外诊疗记录，包括门诊记录、住院记录、检查检验记录以及用药记录。'
        },
        {
          title: '体验记录',
          des: '系统支持对接体检系统自动采集患者体检数据、同时也支持按模板手动录入或批量导入等方式便捷录入院外体检数据。'
        },
        {
          title: '问卷记录',
          des: '系统支持多种终端填写自定义问卷量表，通过问卷量表收集居民的健康补充信息。后台支持查看随访问卷填写记录、得分情况，对于需要关注的异常随访问卷系统会根据问卷结果自动进行标记提醒。'
        }
      ]
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {

  }
};
</script>

<style lang='scss' scoped>
.product-wrapper {
  .product-content {
    background: #fff;
    padding-bottom: 240px;
  }
  .pro-content {
    width: 1238px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .content-item {
      width: 606px;
      .item-title {
        display: flex;
        align-items: center;
        padding-left: 12px;
        height: 40px;
        color: #333;
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 40px;
        .title-bg {
          width: 40px;
          height: 40px;
          background: #0279ff;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 10px;
          img {
            width: 29px;
            height: 29px;
          }
        }
      }
      .item-des {
        width: 100%;
        height: 120px;
        color: #333;
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
  .evaluate-content {
    width: 100%;
    height: 420px;
    background: url('~@/assets/product/evaluate-bg.png') no-repeat center center;
    background-size: cover;
    padding-top: 30px;
    .evaluate-warp {
      display: flex;
      flex-direction: column;
      align-items: center;
      .evaluate-title {
        font-size: 28px;
        color: #333;
        line-height: 40px;
        margin-bottom: 16px;
      }
      .img-list {
        display: flex;
        justify-content: space-between;
        margin-bottom: 46px;
        .img {
          width: 256px;
          height: 236px;
          margin-right: 186px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
      .title-list {
        display: flex;
        align-items: center;
        .text {
          font-size: 20px;
          line-height: 28px;
          color: #333333;
          font-weight: 600;
        }
        .line {
          width: 220px;
          height: 1px;
          border: 1px solid #0279ff;
          margin: 0 50px;
          position: relative;
          &::before {
            content: '';
            display: block;
            width: 6px;
            height: 6px;
            background: #0279ff;
            border-radius: 50%;
            position: absolute;
            top: -3px;
            left: -6px;
          }
          &::after {
            content: '';
            display: block;
            width: 6px;
            height: 6px;
            background: #0279ff;
            border-radius: 50%;
            position: absolute;
            top: -3px;
            right: -6px;
          }
        }
      }
    }
  }
  .introduction-content {
    width: 1365px;
    margin: 0 auto;
    text-align: center;
    .img1 {
      width: 100%;
      height: 882px;
      margin-bottom: 80px;
    }
    .img2 {
      width: 1361px;
      height: 857px;
    }
  }
  .archival-content {
    width: 1404px;
    margin: 0 auto;
    display: flex;
    margin-bottom: 40px;
    .content-item {
      width: 234px;
      height: 320px;
      padding: 40px 24px 0;
      .item-i {
        font-size: 40px;
        line-height: 40px;
        color: #333333;
        margin-bottom: 4px;
        font-weight: 600;
      }
      .item-title {
        width: 102px;
        line-height: 22px;
        padding-bottom: 8px;
        border-bottom: 1px solid #999999;
        margin-bottom: 10px;
      }
      .item-des {
        color: #666666;
        line-height: 20px;
        font-size: 14px;
      }
    }
    .first {
      background: url('~@/assets/product/archival-first.png') no-repeat center center;
      background-size: cover;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 24px;
      font-weight: 600;
    }
    .bg-1 {
      background: linear-gradient(180deg,#f3f9ff, #f8fcff);
    }
    .bg-2 {
      background: linear-gradient(180deg,#faf3ff, #f8fcff);
    }
    .bg-3 {
      background: linear-gradient(180deg,#f5fff3, #f8fcff);
    }
    .bg-4 {
     background: linear-gradient(180deg,#f3f6ff, #f8fcff);
    }
    .bg-5 {
      background: linear-gradient(180deg,#f2f2f2, #f8fcff);
    }
  }
}
</style>
