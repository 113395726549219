<template>
  <div class="banner-wrapper" :style="styleComp">
    <div class="banner-text">
      <div class="text-title">{{ title }}</div>
      <div class="text-sub mb-12">{{ describe }}</div>
      <div v-if="!!describe2" class="text-sub">{{ describe2 }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Banner',
  props: {
    title: {
      type: String,
      default: ''
    },
    describe: {
      type: String,
      default: ''
    },
    describe2: {
      type: String,
      default: ''
    },
    banner: {
      type: String,
      default: ''
    }
  },
  computed: {
    styleComp() {
      if (this.banner) {
        return ` background: url(${this.banner}) no-repeat center center; background-size: cover;`;
      }
      return null;
    }
  }
};
</script>
<style lang="scss" scoped>
.banner-wrapper {
  width: 100%;
  height: 560px;
  background: url('https://static.zhongjiadata.com/images/20250227/29081711d33a49a7a0cf860a7adb1ff6.png') no-repeat center center;
  background-size: cover;
  .banner-text {
    padding-top: 142px;
    width: 1400px;
    margin: 0 auto;
    padding-left: 110px;
    .text-title {
      font-weight: 600;
      color: #ffffff;
      line-height: 55px;
      font-size: 40px;
      margin-bottom: 20px;
    }
    .text-sub {
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
      width: 500px;
    }
    .mb-12 {
      margin-bottom: 12px;
    }
  }
}
</style>
